<template>
  <div class="container">
    <!--<scopped-options :options="options" :type="type" :alreadySelected="alreadySelected(type)"/>-->
    <scopped-options :actionLinksPortalTarget="actionLinksPortalTarget" :options="options" :type="currentOption" v-if="currentStep == 'Option' || currentStep == 'All Options'"/>

    <div class="row" v-if="currentStep == 'All Options'">
      <template v-if="actionLinksPortalTarget">
        <portal :to="actionLinksPortalTarget">
          <div class="form__row form__row_full form__row_margin form__row_start">

            <button class="btn_transparent btn"
                    type="submit"
                    @click="putData"
                    :disabled="disabledFromIncomplete"
            ><svg-icon name="solid/floppy-disk"></svg-icon>
              SAVE
            </button>

            <div class="action-wrapper">
              <button class="trigger btn btn_transparent" @click="toggleActionLinks">
                <svg-icon name="chevron-up"
                  v-if="isOpen"
                  size="lg"
                />

                <svg-icon name="chevron-down"
                  v-if="!isOpen"
                  size="lg"
                />
              </button>

              <transition name="fade">
                <div class="actions" :class="{'show-actions': isOpen}">
                  <button class="btn_transparent btn"
                          type="button"
                          @click="createNew"
                          :disabled="disabledFromIncomplete"
                  ><svg-icon name="solid/plus"></svg-icon> SAVE & CREATE NEW
                  </button>

                  <button class="btn_transparent btn"
                          type="button"
                          @click="clone"
                          :disabled="disabledFromIncomplete"
                  ><svg-icon name="solid/clone"></svg-icon> SAVE & CLONE
                  </button>
                </div>
              </transition>
            </div>


          </div>
        </portal>
      </template>
      <template v-else>
        <div class="form__row form__row_full form__row_margin form__row_start ml-2">
          <button class="btn_default btn"
                  type="submit"
                  @click="putData"
                  :disabled="disabledFromIncomplete"
          >DONE
          </button>

          <button class="btn_default btn"
                  type="button"
                  @click="createNew"
                  :disabled="disabledFromIncomplete"
          ><svg-icon name="solid/plus"></svg-icon> SAVE & CREATE NEW
          </button>

          <button class="btn_default btn"
                  type="button"
                  @click="clone"
                  :disabled="disabledFromIncomplete"
          ><svg-icon name="solid/clone"></svg-icon> SAVE & CLONE
          </button>

        </div>
      </template>

    </div>
    <transition name="fade">
      <warning-out-of-square tabindex="0" :order="order"/>
    </transition>
  </div>
</template>

<script>
  import router from "../../router";
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import {defineAsyncComponent} from "vue";

  const warningOutOfSquare = defineAsyncComponent(() => import('../modals/WarningOutOfSquare'));
  const breadcrumbs = defineAsyncComponent(() => import("./../parts/breadcrumbs"));
  const ScoppedOptions = defineAsyncComponent(() => import("./options/ScoppedOptions"));

  export default {
    name: "SelectOptions",

    components: {
      breadcrumbs,
      warningOutOfSquare,
      ScoppedOptions,
    },

    props: {
      order: Boolean,
      actionLinksPortalTarget: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        doneOptions: ['MESH', 'INS', 'INSCOLOUR', 'LOCK', 'HOPH', 'CYL', 'WHL', 'HNG', 'LFTT', 'MFLP', 'PRTS'],
        packageName: '',
        selectedOptions: {},
        prevOption: '',
        isOpen: true,
      }
    },

    methods: {
      ...mapMutations('options', ['setOptionsToOrder', 'setOptionsToPackage', 'setShowAll']),

      toggleActionLinks() {
        this.isOpen = !this.isOpen;
      },

      putData() {
          this.$store.dispatch('orderPosition/save', 0);
      },

      createNew() {
          this.$store.dispatch('orderPosition/save', 1);
      },

      clone() {
          this.$store.dispatch('orderPosition/save', 2);
      },


      ckeckAvailableOptions() {
        if (!this.allOptions && this.type !== '' && this.type !== undefined) {
          let result = _.find(this.doneOptions, (o) => {
            return o === this.type;
          });
          if (result === undefined) {
            this.$store.commit('options/setShowAll', true);
            this.$store.dispatch('options/getOptionsNew');
          }
          else {
            this.$store.commit('options/setShowAll', false);
          }
        }
      },

      alreadySelected(name) {
        return this.$store.getters['selected/getSelectedOption'](name);
      }
    },

    computed: {

      isEditOrderItem() {
        return this.$store.getters['orderItem/get']('isUpdate');
      },

      isSetState() {
        return this.$store.getters['sets/get']('isSetState');
      },

      getUserName() {
        return localStorage.getItem('nameUser');
      },

      getSelectedOptions() {
        return this.selectedOptions;
      },

      ...mapGetters({
        IsEdit: 'packages/IsEdit',
        IsEditFromSet: 'packages/IsEditFromSet',
        IsEditFromPackage: 'packages/IsEditFromPackage',
        user: 'auth/nameUser',
        options: 'options/options',
        optionsList: 'options/optionsList',
        type: 'options/type',
        orderItem: 'createdOrder/createdOrder',
        update: 'types/updates',
        currentStep: 'orderPosition/currentStep',
        currentOption: 'orderPosition/currentOption'
      }),

      ...mapState('options', ['allOptions', 'itemId', 'incomplete']),

      disabledFromIncomplete() {
        if (this.incomplete.length > 0) {
          return true;
        }
        else return false;
      },
    },

      created(){
      },
      mounted(){
      },

    beforeUpdate() {
      this.ckeckAvailableOptions();
    },

  }
</script>

<style lang="scss" scoped>

  @import '../../assets/scss/utils/vars';

  .option_name {
    text-transform: capitalize;
    text-align: center;
  }

  .form__input {
    border: none;
    border-bottom: 2px solid $navy;
  }

  .trigger {
    display: none;
  }

  .action-wrapper {
    position: relative;
  }

  @media screen and (max-width: $sm){
    .show-actions {
      display: none;
    }

    .action-click-away{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      /* z-index: -10; */
      pointer-events: auto;
    }

    .actions {
      position: absolute;
      top: 0;
      z-index: 10;
      background-color: $navy;
      transform: translateY(-120%);
      border-radius: 0.375rem;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
      .btn {
        font-size: .8rem;
        white-space: nowrap;
        margin: 0;
        width: 100%;
        border: none;
        padding: .75rem .75rem;
        border-bottom: 1px solid #f4f5f7;;
        &:last-child {
          border-bottom: none;
        }
      }
    }


    .trigger {
      display: block;
    }
  }
</style>
